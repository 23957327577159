<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline account-top"
    >
      <el-form-item label="发贴帐号">
        <el-input
          v-model="queryForm.account"
          placeholder="发帖帐号id"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="任务状态">
        <el-select v-model="queryForm.status" placeholder="任务状态" clearable>
          <el-option label="完成" value="1"></el-option>
          <el-option label="待执行" value="0"></el-option>
          <el-option label="执行失败" value="-1"></el-option>
          <el-option label="帐号失效" value="-11"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发帖用户" v-if="userInfo.is_staff">
        <el-input
          v-model="queryForm.user"
          placeholder="发帖用户id"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="搜索">
        <el-input
          v-model="queryForm.search"
          placeholder="评论内容"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-button type="primary" @click="fetchData">刷新</el-button>
      <el-button type="danger" @click="batchDel">批量删除</el-button>
    </el-form>
    <el-table
      v-loading="listLoading"
      :data="tableData"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      class="tableList2"
    >
      <el-table-column type="selection" width="30"></el-table-column>
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column
        prop="tweet_url"
        label="帖子链接"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column prop="accountId" label="评论帐号ID"></el-table-column> -->
      <el-table-column prop="account_entity" label="评论帐号">
        <template slot-scope="scope">
          {{
            scope.row.account_entity
              ? '@' + scope.row.account_entity.screen_name
              : null
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="comment_time"
        label="设定评论时间"
      ></el-table-column>
      <el-table-column
        prop="content"
        label="评论内容"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="getStatusTip(scope.row.status, statusMap)"
            placement="top"
          >
            <el-tag
              :type="getStatusType(scope.row.status, statusMap)"
              disable-transitions
            >
              {{ getStatusLabel(scope.row.status, statusMap) }}
            </el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="创建人" v-if="isManager">
        <template slot-scope="scope">
          {{
            scope.row.user_entity ? scope.row.user_entity.oauth_account : null
          }}
        </template>
      </el-table-column>
      <el-table-column label="创建人备注" v-if="isManager">
        <template slot-scope="scope">
          {{ scope.row.user_entity ? scope.row.user_entity.note : null }}
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="任务创建时间"
      ></el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.pageIndex"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { delCommentTaskByIds, getCommentTask } from '@/api/twitter'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { getManagerStatus } from '@/utils/manager'
export default {
  name: 'CommentTask',
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      listLoading: true,
      multipleSelection: [],
      query: {},
      tableData: [],
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        account: '',
        status: '',
        user: '',
        search: ''
      },
      // page: 1,
      // limit: 10,
      total: 0,
      isManager: getManagerStatus(),
      statusMap: {
        1: {
          type: 'success',
          label: '执行成功'
        },
        0: {
          type: 'primary',
          label: '待执行'
        },
        '-1': {
          type: 'danger',
          label: '执行失败',
          tip: '任务执行失败，该任务将由人工介入处理'
        },
        '-2': {
          type: 'danger',
          label: '链接格式错误',
          tip:
            '链接格式不是标准的的推文链接，标准格式如：https://twitter.com/Sheridan6667/status/1460874463432503297'
        },
        '-3': {
          type: 'danger',
          label: '链接失效',
          tip: '该推文链接已经无法访问'
        },
        '-4': {
          type: 'danger',
          label: '评论超长',
          tip:
            'twitter限制评论长度为280字符，详情请查阅twitter规则说明：https://help.twitter.com/en/using-twitter/how-to-tweet'
        },
        '-5': {
          type: 'danger',
          label: '重复评论',
          tip: '已存在相同的评论，请修改评论内容后重新提交'
        },
        '-10': {
          type: 'danger',
          label: '已删除'
        },
        '-11': {
          type: 'danger',
          label: '帐号失效',
          tip: '该twitter帐号已失效，请更换twitter帐号重试'
        }
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,

    async fetchData() {
      this.listLoading = true
      // eslint-disable-next-line camelcase
      const { items, all_count: total } = await getCommentTask(this.queryForm)
      this.tableData = items
      // eslint-disable-next-line camelcase
      this.total = total
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.pageIndex = 1
      await this.fetchData()
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.queryForm.pageIndex = val
      this.fetchData()
    },
    handleSelectionChange(items) {
      this.multipleSelection = []
      items.forEach(item => {
        if (this.multipleSelection.indexOf(item.id) === -1) {
          this.multipleSelection.push(item.id)
        }
      })
    },
    batchDel() {
      if (this.multipleSelection.length < 1) {
        this.$message.error('请至少选择一项需要删除的数据')
      } else {
        this.$confirm('此操作将删除该任务, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await delCommentTaskByIds(this.multipleSelection).then(resp => {
              this.fetchData()
            })
          })
          .catch(() => {})
      }
    }
  }
}
</script>

<style scoped></style>
